@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

html {
  box-sizing: border-box;
  color-scheme: dark;
}

html, body {
  background-color: #282828;
}

body, input, select, textarea, button {
  font-family: Rubik, Plus Jakarta Sans, sans-serif;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  letter-spacing: -.05rem;
  font-weight: 700;
}

h3 {
  letter-spacing: -.032rem;
}

p {
  letter-spacing: -.028rem;
  font-weight: 500;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button:hover:not(:disabled) {
  cursor: pointer;
}

button:disabled {
  opacity: .4;
}

html {
  box-sizing: border-box;
  color-scheme: dark;
}

html, body {
  background-color: #282828;
}

body, input, select, textarea, button {
  font-family: Rubik, Plus Jakarta Sans, sans-serif;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  letter-spacing: -.05rem;
  font-weight: 700;
}

h3 {
  letter-spacing: -.032rem;
}

p {
  letter-spacing: -.028rem;
  font-weight: 500;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button:hover:not(:disabled) {
  cursor: pointer;
}

button:disabled {
  opacity: .4;
}

section {
  height: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

section:nth-child(2) {
  background-image: linear-gradient(#282828, #1e1e1e);
}

h1 {
  color: #d79920;
  margin: 0;
  font-family: "Press Start 2P", cursive;
  font-size: 68px;
}

a {
  color: inherit;
}

p {
  color: #c7b6a1;
}

.linkedin {
  -webkit-mask-image: url("linkedin.8511ec51.svg");
  mask-image: url("linkedin.8511ec51.svg");
}

.github {
  -webkit-mask-image: url("github.c2e1babd.svg");
  mask-image: url("github.c2e1babd.svg");
}

.soundcloud {
  -webkit-mask-image: url("soundcloud.f78fd0b9.svg");
  mask-image: url("soundcloud.f78fd0b9.svg");
}

.scroll-appear {
  opacity: 0;
  transition: opacity .8s cubic-bezier(.5, 1, .5, 1) .4s, transform .8s cubic-bezier(.5, 1, .5, 1) .5s;
  transform: translateY(20px);
}

.up {
  transform: translateY(20px);
}

.down {
  transform: translateY(-20px);
}

.left {
  transform: translateX(20px);
}

.right {
  transform: translateX(-20px);
}

.is-visible {
  opacity: 1;
  transform: none;
}

.page {
  height: 100vh;
  width: 99vw;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

ul {
  max-width: 50%;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  display: flex;
}

li {
  height: 36px;
  width: 36px;
  background-color: #a89984;
  justify-content: center;
  align-items: center;
  transition: all .5s;
  display: flex;
  transform: scale(1);
  -webkit-mask-size: 36px;
}

li:hover {
  background-color: #fbf1c7;
  transition: all .2s ease-out;
  transform: scale(1.1);
}

li:not(:last-child) {
  margin-right: 20px;
}

pre {
  color: #b2b625;
  margin: 28px auto 20px;
  font-size: 16px;
}

pre > a:hover {
  color: #8d9018;
  transition: all .1s;
}

pre:first-child {
  font-size: 18px;
}

a {
  height: 36px;
  width: 36px;
}

.loader {
  width: 55px;
  aspect-ratio: 1;
  --g1: conic-gradient(from 90deg at 3px 3px, #0000 90deg, #98971a 0);
  --g2: conic-gradient(from -90deg at 22px 22px, #0000 90deg, #98971a 0);
  background: var(--g1), var(--g1), var(--g1), var(--g2), var(--g2), var(--g2);
  background-repeat: no-repeat;
  background-size: 25px 25px;
  animation: 1.5s infinite load;
}

@keyframes load {
  0% {
    background-position: 0 0, 0 100%, 100% 100%;
  }

  25% {
    background-position: 100% 0, 0 100%, 100% 100%;
  }

  50% {
    background-position: 100% 0, 0 0, 100% 100%;
  }

  75% {
    background-position: 100% 0, 0 0, 0 100%;
  }

  100% {
    background-position: 100% 100%, 0 0, 0 100%;
  }
}

/*# sourceMappingURL=index.260c0f57.css.map */
