/*
  ********************************
  *********SCROLL_APPEAR**********
  ********************************
*/
.scroll-appear {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s cubic-bezier(0.5, 1, 0.5, 1),
    transform 0.8s cubic-bezier(0.5, 1, 0.5, 1);
  transition-delay: 0.4s, 0.5s;
}

.up {
  transform: translateY(20px);
}

.down {
  transform: translateY(-20px);
}

.left {
  transform: translateX(20px);
}

.right {
  transform: translateX(-20px);
}

.is-visible {
  opacity: 1;
  transform: none;
}
