.linkedin {
  -webkit-mask-image: url(../assets/linkedin.svg);
  mask-image: url(../assets/linkedin.svg);
}
.github {
  -webkit-mask-image: url(../assets/github.svg);
  mask-image: url(../assets/github.svg);
}
.soundcloud {
  -webkit-mask-image: url(../assets/soundcloud.svg);
  mask-image: url(../assets/soundcloud.svg);
}
