@use "td-scss" as td;

section {
  height: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

section:nth-child(2) {
  background-image: linear-gradient(to bottom, td.$bg, darken(td.$bg, 4%));
}

h1 {
  color: td.$yellow8bit;
  font-family: "Press Start 2P", cursive;
  font-size: 68px;
  margin: 0;
}

a {
  color: inherit;
}

p {
  color: #c7b6a1;
}
