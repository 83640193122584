@use "td-scss" as td;

@import "globals.scss";
@import "partials/_logos.scss";
@import "partials/_scroll-appear.scss";

$svg-size: 36px;

.page {
  height: 100vh;
  width: 99vw;

  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

ul {
  list-style-type: none;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 50%;
  margin: 0 auto;
}

li {
  height: $svg-size;
  width: $svg-size;

  -webkit-mask-size: $svg-size;
  background-color: td.$fg-5;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  transform: scale(1);
}

li:hover {
  transition: 0.2s;
  transform: scale(1.1);
  transition-timing-function: ease-out;
  background-color: td.$fg;
}

li:not(:last-child) {
  margin-right: 20px;
}

pre {
  font-size: 16px;
  margin: 28px auto 20px;
  color: td.$green-pre;
}

pre > a:hover {
  transition: 0.1s;
  color: darken(saturate(td.$green-pre, 5%), 10%);
}

pre:nth-child(1) {
  font-size: 18px;
}

a {
  height: $svg-size;
  width: $svg-size;
}

/*
  ********************************
  ************SPINNER*************
  ********************************
*/
.loader {
  width: 55px;
  aspect-ratio: 1;
  --g1: conic-gradient(from 90deg at 3px 3px, #0000 90deg, #98971a 0);
  --g2: conic-gradient(from -90deg at 22px 22px, #0000 90deg, #98971a 0);
  background: var(--g1), var(--g1), var(--g1), var(--g2), var(--g2), var(--g2);
  background-size: 25px 25px;
  background-repeat: no-repeat;
  animation: load 1.5s infinite;
}
@keyframes load {
  0% {
    background-position: 0 0, 0 100%, 100% 100%;
  }
  25% {
    background-position: 100% 0, 0 100%, 100% 100%;
  }
  50% {
    background-position: 100% 0, 0 0, 100% 100%;
  }
  75% {
    background-position: 100% 0, 0 0, 0 100%;
  }
  100% {
    background-position: 100% 100%, 0 0, 0 100%;
  }
}
